<template>
  <div class="container">

    <div class="container_block">

      <template
        v-for="item in list"
        :key="item.id"
      >
        <div
          class="container_block_row"
          @click="toDeital(item.noticeId)"
        >
          <div class="container_block_left">
            <span><img
                :src="item.imgUrl?item.imgUrl:pic"
                alt=""
              ></span>
          </div>
          <div class="container_block_right">
            <div class="container_block_right_info">
              <div class="container_block_right_title"> {{item.noticeTitle}}</div>
              <div
                class="container_block_right_desc"
                v-html="item.noticeContent"
              ></div>

            </div>
            <div class="container_block_right_date">{{item.createTime}}</div>
          </div>
        </div>
        <el-divider />
      </template>

      <div class="container_block_page">
        <pagination
          v-show="total > 0"
          :total="total"
          v-model:page="query.pageNum"
          v-model:limit="query.pageSize"
          @pagination="onLoad"
        />

        <!-- <div class="container_block_page_pre"> <el-button  type="warning" size="large" ><el-icon class="el-icon--left"><ArrowLeft /></el-icon>上一页</el-button></div>
            <div class="container_block_page_info"> <span>共{{total}}条数据</span>  <span>1/2页</span></div>
            <div class="container_block_page_next">
              <el-button type="warning" size="large">下一页<el-icon class="el-icon--right"><ArrowRight /></el-icon>
    </el-button></div> -->
      </div>

    </div>
  </div>
</template>
<script setup name="NewsList">
import { ref } from "vue";
import { useRouter } from "vue-router";
import { getNews } from "@/api/news";
import Pagination from '@/components/Pagination'

const list = ref([]);
/*const stationId = ref(null);*/
const refreshing = ref(false);
const isEmpty = ref(false)
const total = ref(0)
const query = ref({})
query.value = { pageNum: 1, pageSize: 10, noticeType: 1 }
const router = useRouter();
const toDeital = (id) => {
  router.push({
    path: 'newsDetail', query: {
      id
    },
  })
}
const onLoad = async () => {
  if (refreshing.value) {
    list.value = [];
    refreshing.value = false;
  }
  let result = await getNews(query.value);
  if (result?.code === 200) {
    list.value = result?.rows;
    total.value = result.total
    isEmpty.value = false;
    // if (list.value.length < result?.total) {
    //   query.value.pageNum++;
    // }
  } else if (result?.code === 410) {

    list.value = [];
    isEmpty.value = true;
  } else {
    isEmpty.value = true;
  }
};
onLoad()
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  background-color: #f5f5f5;
  padding-top: 30px;
  &_block {
    margin: 0 auto;
    padding: 30px 30px 30px 30px;
    width: 62%;
    background: #fff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    &_row {
      display: flex;
      flex-direction: row;
    }
    &_left {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 18px;
      color: #000000;
    }
    &_left img {
      width: 160px;
      height: 120px;
      margin-right: 20px;
    }
    &_right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      &_info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      &_title {
        color: #212121;
        font-size: 18px;
        font-weight: bold;
      }
      &_desc {
        text-align: left;
        margin-top: 10px;
        color: #c2c7cc;
        font-size: 14px;
      }
      &_date {
        justify-self: flex-end;
        color: #505559;
        font-size: 14px;
      }
    }
    &_right img {
      height: 24px;
      vertical-align: middle;
    }
    &_page {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      &_pre {
        margin-right: 50px;
        color: white;
      }
      &_info {
        color: #8ca2aa;
        font-size: 18px;
      }
      &_next {
        margin-left: 50px;
      }
    }
  }
}
.--el-button-text-color {
  color: white;
}
:deep(.el-pagination.is-background .el-pager li:not(.is-disabled).is-active) {
  background-color: #ff9959;
}
</style>
